

























import Vue from "vue";
import InventarioGeneralService, {
  GetMovimientoInventarioDto
} from "@/api/inventario/general/InventarioGeneralService";
export default Vue.extend({
  data() {
    return {
      search: "",
      movimientos: [] as GetMovimientoInventarioDto[],
      headers: [
        { text: "id", value: "id" },
        { text: "quantity", value: "quantity" },
        { text: "TypeDisplay", value: "getTypeDisplay" },
        { text: "FechaDisplay(EC)", value: "getFechaDisplay" },
        { text: "Description", value: "description" }
      ]
    };
  },
  methods: {
    loadMovimientos() {
      InventarioGeneralService.getMovimientos(this.$route.params.articuloID)
        .then(datos => {
          this.movimientos = datos;
        })
        .catch(() => {
          //
          console.log("no se obtuvo movimientos");
        });
    }
  },
  created() {
    this.loadMovimientos();
  }
});
