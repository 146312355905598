import httpClient from "@/api/http-client";

const ENDPOINT = "/inventario/general";

export interface GetMovimientoInventarioDto {
  id: number;
}

class InventarioGeneralService {
  getMovimientos(
    articuloID: string | number
  ): Promise<GetMovimientoInventarioDto[]> {
    return httpClient
      .get(`${ENDPOINT}/articulo/${articuloID}/movimiento`)
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          return [];
        }
      });
  }
}
export default new InventarioGeneralService();
